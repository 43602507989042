import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import('pages/help/rules'));

const routes: Route[] = [
  {
    name: id,
    path: '/:lang?/help/rules',
    element: <AsyncPage />,
  },
];

export default routes;
