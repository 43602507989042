import { link } from 'services/navigation';
import authorization from 'services/routing/authorization';
import type { NavigationMenuItem } from 'contracts';

import messages from '../rules.i18n';

import id from './id';

const menu: NavigationMenuItem[] = [
  {
    name: id,
    parent: 'helpAndInfo',
    label: messages.pages.rules.navigationMenu.label,
    link: () => link[id](),
    weight: 5,
    visibility$: authorization.getAuthorization$(id),
  },
];

export default menu;
